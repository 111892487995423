import { navigate } from "gatsby"
import moment from "moment"

export function change_gravatar(id, pictureUrl) {
  let change = false
  let oImg = document.getElementById(id)
  let changeUrl = "https://sdn.geekzu.org/" + pictureUrl.replace(/https:\/\/\S+?\//g, "")
  let timer = setTimeout(function () {
    change = true
    if (document.getElementById(id)) {
      document.getElementById(id).src = changeUrl
    }
  }, 3000)
  oImg.onload = function () {
    clearTimeout(timer)
    timer = null
  }
  oImg.onerror = function () {
    clearTimeout(timer)
    timer = null
    if (document.getElementById(id) && !change) {
      document.getElementById(id).src = changeUrl
    }
  }
}

export function checkPassword(isAuthenticated, user) {
  if (isAuthenticated) {
    if (user.sub !== undefined && user.sub !== null && !user.sub.startsWith("auth0")) return false
    const resetAt = user["https://api.codelinaro.org/reset_at"]
    if (moment().isAfter(moment(resetAt).add({ months: 6 }))) {
      if (
        window.location.pathname === "/secure/settings/" ||
        window.location.pathname === "/secure/settings"
      ) {
        return true
      } else {
        navigate("/secure/settings/")
        return true
      }
    }
  }
  return false
}

export function isValidEmail(value) {
  var emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
  return emailPattern.test(value)
}

export function getProjectUrlparams(props) {
  let parentOrganization = props.parentOrganization
  if (props.currentProject) {
    parentOrganization = props.currentProject.parentOrganization
  }
  let urlparams = ""
  if (props.isSuperAdmin) {
    urlparams = `parentOrganization=${parentOrganization}`
  } else {
    urlparams = `index=${props.orgIndex}`
  }
  return urlparams
}
export function sleep(milliseconds) {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export function isValidUrl(string) {
  let url

  try {
    url = new URL(string)
  } catch (_) {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}
export function html2text(html) {
  html = html.replace(/(\n)/g, "")
  html = html.replace(/(\t)/g, "")
  html = html.replace(/(\r)/g, "")
  html = html.replace(/<\/?[^>]*>/g, "")
  html = html.replace(/(\s)/g, "")
  return html
}

export function containsSpecialChars(str) {
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
  return specialChars.test(str)
}

export function containsWhiteSpace(value) {
  return value.indexOf(" ") >= 0
}

export function generateRandomInteger(max) {
  return Math.floor(Math.random() * max) + 1
}

export function getUrlParams(url) {
  let theRequest = new Object()
  if (url.indexOf("?") != -1) {
    let str = url.substr(1)
    let strs = str.split("&")
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split("=")[0]] = decodeURIComponent(strs[i].split("=")[1])
    }
  }
  return theRequest
}

export function toMinutes(mins) {
  var fieldArray = mins.split(":")
  var minutes = parseInt(fieldArray[0]) * 60 + parseInt(fieldArray[1])
  return minutes
}

export function getMonthYearList(monthsOld = 6) {
  var monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]
  var data = []

  var today = new Date()
  var d
  var month
  var year

  for (var i = monthsOld; i >= 0; i -= 1) {
    d = new Date(today.getFullYear(), today.getMonth() - i, 1)
    month = monthNames[d.getMonth()]
    var firstDay = new Date(d.getFullYear(), d.getMonth(), 1)
    var lastDay = new Date(d.getFullYear(), d.getMonth() + 1, 0)

    firstDay = formatDate(firstDay)
    lastDay = formatDate(lastDay)
    year = d.getFullYear()
    var nMonth = `${d.getMonth() + 1}`.padStart(2, "0")
    data.push({
      key: `${year}${nMonth}`,
      month: `${year}-${nMonth}`,
      value: `${year}-${month}`,
      firstDay: firstDay,
      lastDay: lastDay,
    })
  }
  return data
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (month.length < 2) month = "0" + month
  if (day.length < 2) day = "0" + day

  return [year, month, day].join("-")
}

export function project_metrics_get_start_date(days = 0) {
  const queryParams = new URLSearchParams(window.location.search)
  const currentDate = new Date()
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1)

  let startDay = firstDayOfMonth

  if (days > 0) {
    const daysAgo = new Date()
    daysAgo.setDate(currentDate.getDate() - days)
    daysAgo.setHours(0, 0, 0, 0)
    startDay = daysAgo
  }

  var sd = queryParams.get("startDate") ? new Date(queryParams.get("startDate")) : startDay //new Date(moment.utc().startOf("month"))
  // console.log(sd)
  return sd
}

export function project_metrics_get_end_date() {
  const queryParams = new URLSearchParams(window.location.search)
  var ed = queryParams.get("endDate")
    ? new Date(queryParams.get("endDate"))
    : new Date(moment.utc())
  // console.log(ed)
  return ed
}

export function getDateDiffInSeconds(date1, date2) {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    throw new Error("Invalid date object.")
  }

  const diffInMilliseconds = Math.abs(date2.getTime() - date1.getTime())
  const seconds = Math.floor(diffInMilliseconds / 1000)
  return seconds
}
